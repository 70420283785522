.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch .toggle-switch-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-switch-checkbox:checked + .slider {
  background-color: #2196f3;
}

.toggle-switch-checkbox:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.toggle-switch-checkbox:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*========== Modal Window (Popup) CSS */
.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: azure;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  min-width: 50vw;
  justify-content: space-evenly;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1em;
  border-radius: 10px;
  padding: 2em;
  max-height: 90vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.modalContent::-webkit-scrollbar {
  display: none;
}
/* ============ Modal Window (Popup) CSS Ends */

/*============ Forms Styles */

/* .priorityoneform  .form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
} */
.priorityoneform .form-input-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.priorityoneform .form-actions-wrap {
  display: flex;
  flex-wrap: wrap;
}

.priorityoneform .form-group {
  display: flex;
  flex-direction: column;
}

.priorityoneform .label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.priorityoneform .error-message,
.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

.priorityoneform .submit-button {
  margin-top: 1rem;
}

.priorityoneform .react-datepicker-popper {
  z-index: 2;
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]).bottomBorderMenu {
  border-bottom: 2px solid rgb(226 232 240 / var(--tw-divide-opacity));
}

/*=================== Forms Style */

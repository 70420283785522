.billingDetailsWrap, .partnerCompanyUserListItem{
        width: 100%;
        justify-content: space-between;
        border: 1px solid #ececec;
        padding: 15px;
        max-width: 520px;
}
.partnerCompanyListItem{
        /* width: 100%; */
        /* justify-content: space-between; */
        border: 1px solid #ececec;
        padding: 15px;
        /* max-width: 520px; */
}

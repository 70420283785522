a{
    text-decoration: none;
}
.homeBanner{
    background-color: #0F083E;
}
.sectionHeading, .gridSectionHeading, .heading{
    font-weight: 400;
    font-size: 45px;
}
.tileHeadingGreenBlack{
        background-color: #F4BC52;
        display: flex;
        padding: 10px;
        height: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-radius: 6px 0px 0px 6px;
        position: relative;
}
.tileHeadingGreenBlack::before {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 12px solid #F4BC52;
}
img.tileImage{
    /* height: 250px; */
    /* width: 250px; */
}
.tileSubHeading, .subHeading{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
}
.tileDescription{
    font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
}
.howItWorksSection .gridItemContainer {
    /* max-width: 20%; */
    min-width: 320px;
}

@media (max-width: 480px) {
.downloadAppSection,
    .findLocationSection {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 2em;
    }
}

.forBusinessSection .gridItemContainer {
    /* max-width: 33%; */
    /* min-width: 320px; */
}
.newsletterSubscribeSection{
    background-color: #F4BC52;
}
.subscribeWidgetInnerSection >* {
    min-height:3em;
}
.newsletterSubscribeWidget input.submitButton:hover{
    background-color:#0F083E !important;
}
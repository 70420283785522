/* nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
} */

/* nav ul li {
    display: flex;
    align-items: center;
    padding: 10px;
    transition: background-color 0.2s ease;
} */

/* nav ul li:hover {
    background-color: #f2f2f2;
} */

/* nav ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
} */

/* nav ul li a:hover {
    color: #007bff;
} */

/* nav ul li svg {
    margin-right: 5px;
} */

.submenu {
    display: none;
    background-color: #f2f2f2;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
}

/* nav ul li:hover .submenu {
    display: block;
} */

/* .submenu li {
    padding: 5px;
} */
/* 
.submenu li a {
    display: block;
    color: #333;
    text-decoration: none;
} */

/* .submenu li a:hover {
    color: #007bff;
} */

.sidebar {
    /* position: fixed; */
    /* top: 0;
    left: 0; */
    width: 20vw;
    /*height: 100vh; */
    /* background-color: #ededed; */
    height:inherit;
    min-height: 100vh;

    /* border: 1px solid rgba(103, 104, 102, .2) */
}

.menu a {
    /* display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    color: #6D6D6D; */
}

.menu a:hover {
    /* background-color: #cdcbff; */
}

.menu .active {
    font-weight: bold;
    /* background-color:#ececec; */
    /* color: #fff; */
}

.menu-item.has-children:hover .submenu {
    display: block;
    margin-left: 5px;
    /* Add some space to prevent immediate hiding */
}

.menu-item {
    display: flex;
    /* position: relative; */
    /* padding-right: 25px; */
    /* Add padding to accommodate child menu */
}

/* .submenu li {
    padding: 5px;
} */

